import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { generateDeviceId } from "helpers/utility";

import consts from "../../consts/consts";
import actionTypes from "../consts/actionTypes";
import {
  accountLogin,
  accountLoginWebPlayer,
  accountLogout,
  addFavorite_Channel,
  addFavoriteAsset,
  appleAuth,
  askResetPassword,
  casAddDevice,
  casAuth,
  casRemoveDevice,
  changeAddress,
  changeDetails,
  changeScoreAsset,
  facebookAuth,
  favoriteAssets,
  favoriteChannels,
  getAccountDetails,
  getDeviceList,
  getParentalCode,
  getPasswordPolicy,
  getPurchaseCode,
  googleAuth,
  listInvoices,
  loginByPairing,
  loginDevice,
  removeFavoriteAsset,
  resetPassword,
  setAdultCode,
  setPurchaseCode,
  setSignupStep,
  syncFromTve,
  updatePassword,
  validateAdultCode,
  validatePurchaseCode,
} from "./account";
import { postAnalyticsReport } from "./analytics";
import {
  createNPVRRecord,
  deleteNPVRRecord,
  getAsset,
  getAssetNextEpisode,
  getNPVRRecords,
  getQuotaNPVR,
  getRecord,
  readSlideTrailer,
  readTrailer,
} from "./content";
import {
  askLicenseFP,
  askLicenseWV,
  channelStream,
  getVodLicense,
  listAssetTags,
  readAsset,
  readRecord,
  resetPlayerInfos,
  seekAsset,
  testAsset,
} from "./player";
import { hierarchy, menuOrder } from "./reference";
import {
  activeProducts,
  addOptions,
  addProducts,
  askChangeBankInformation,
  askPayment,
  availableOffers,
  checkOrderStatus,
  createAccount,
  document,
  executePayment,
  listCountries,
  listOptions,
  listPaymentMethods,
  optionValidity,
  ppvOffers,
  removeOptions,
  tvodOffers,
  validateVoucher,
} from "./subscription";

export function login(email, password, client_id, grant_type = "password") {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      // Check Credentials
      if (!email || !password) {
        return reject("Missing params");
      }

      // Execute Api Call
      let loginResponse = await dispatch(accountLogin(email, password, client_id, grant_type));
      if (loginResponse && (loginResponse.access_token || loginResponse.newAuthToken)) {
        // Get Device ID From LocalStorage
        let deviceId = getFromLocal(consts.storageKeys.device);
        let device = { id: deviceId, ...detect() };

        // Generate One
        deviceId =
          device.id && device.id !== "" && device.id !== null && device.id !== undefined
            ? device.id
            : generateDeviceId();
        saveInLocal(consts.storageKeys.device, deviceId);
        // Add Device
        await dispatch(casAddDevice(deviceId));

        //Get New device stored
        device = { id: deviceId, ...detect() };
        // Auth Device
        await dispatch(authDevice(device));
      }
      loginResponse.newAuthToken = loginResponse?.access_token;
      if (!loginResponse || !loginResponse.newAuthToken) {
        return reject(loginResponse);
      }

      // Resolve Promise
      resolve(loginResponse);
    });
  };
}

export function authenticateByApple({ result, client_id }) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let accessToken = result?.authorization?.code;
      if (!accessToken) {
        return reject("Missing params");
      }

      // Execute Api Call
      const loginResponse = await dispatch(appleAuth(accessToken, client_id));
      if (!loginResponse || !loginResponse.newAuthToken) {
        return reject(loginResponse);
      }

      // Resolve Promise
      resolve(loginResponse);
    });
  };
}

export function authenticateByFacebook(result) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let accessToken = result?.authResponse?.accessToken;
      if (!accessToken) {
        return reject("Missing params");
      }

      // Execute Api Call
      const loginResponse = await dispatch(facebookAuth(accessToken));
      if (!loginResponse || !loginResponse.newAuthToken) {
        return reject(loginResponse);
      }

      // Resolve Promise
      resolve(loginResponse);
    });
  };
}

export function authenticateByGoogle({ code, client_id }) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let accessToken = code;
      if (!accessToken) {
        return reject("Missing params");
      }

      // Execute Api Call
      const loginResponse = await dispatch(googleAuth(accessToken, client_id));
      if (!loginResponse || !loginResponse.newAuthToken) {
        return reject(loginResponse);
      }

      // Resolve Promise
      resolve(loginResponse);
    });
  };
}

export function loginWebPlayer() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(accountLoginWebPlayer());
      resolve(result);
    });
  };
}

export function logout() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await accountLogout();
      resolve(result);
    });
  };
}

export function refreshToken() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(loginDevice());
      resolve(result);
    });
  };
}

export function getAccountInfos() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getAccountDetails());
      resolve(result);
    });
  };
}

export function changeAccountDetails(params) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!params) {
        return reject("Missing params");
      }
      const result = await dispatch(changeDetails(params));
      resolve(result);
    });
  };
}

export function changeAccountAddress(params) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!params) {
        return reject("Missing params");
      }
      const result = await dispatch(changeAddress(params));
      resolve(result);
    });
  };
}

export function changePassword(email, oldPassword, password, passwordConfirm) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!email || !oldPassword || !password || !passwordConfirm) {
        return reject("Missing params");
      }
      if (password !== passwordConfirm) {
        return reject("Password error");
      }
      const result = await dispatch(updatePassword(email, oldPassword, password, passwordConfirm));
      resolve(result);
    });
  };
}

export function fetchPasswordPolicy() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(getPasswordPolicy());
      resolve(result);
    });
  };
}

export function askForResetPassword(email) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!email) {
        return reject("Missing params");
      }
      const result = await dispatch(askResetPassword(email));
      resolve(result);
    });
  };
}

export function resetPersonalPassword(email, emailConfirm, token, password, passwordConfirm) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!token || !password || !passwordConfirm) {
        return reject("Missing params");
      }
      if (password !== passwordConfirm) {
        return reject("Password error");
      }
      const result = await dispatch(resetPassword(email, emailConfirm, token, password, passwordConfirm));
      resolve(result);
    });
  };
}
export function getFavoritesChannels(languageId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(favoriteChannels(languageId));
      resolve(result);
    });
  };
}
export function addFavoriteChannels(channels = []) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!channels) {
        return reject("Missing params");
      }
      const result = await dispatch(addFavorite_Channel(channels));
      await dispatch(favoriteChannels());
      resolve(result);
    });
  };
}
export function parentalCode() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getParentalCode());
      resolve(result);
    });
  };
}
export function purchaseCode() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getPurchaseCode());
      resolve(result);
    });
  };
}

export function setParentalCode(adultCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!adultCode) {
        return reject("Missing params");
      }
      const result = await dispatch(setAdultCode(adultCode));
      resolve(result);
    });
  };
}
export function setNewPurchaseCode(purchaseCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!purchaseCode) {
        return reject("Missing params");
      }
      const result = await dispatch(setPurchaseCode(purchaseCode));
      resolve(result);
    });
  };
}

export function validateParentalCode(adultCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!adultCode) {
        return reject("Missing params");
      }
      const result = await dispatch(validateAdultCode(adultCode));
      resolve(result);
    });
  };
}
export function validateOldPurchaseCode(purchaseCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!purchaseCode) {
        return reject("Missing params");
      }
      const result = await dispatch(validatePurchaseCode(purchaseCode));
      resolve(result);
    });
  };
}

export function getDevices() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(getDeviceList());
      resolve(result);
    });
  };
}

export function addDevice(id) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      const result = await dispatch(casAddDevice(id));
      resolve(result);
    });
  };
}
export function authDevice(device) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!device) {
        return reject("Missing Device Infos");
      }
      if (!device.id) {
        return reject("Missing Device Infos - device.id");
      }

      const result = await dispatch(casAuth(device.id, device.os, device.name, device.version));
      resolve(result);
    });
  };
}
export function removeDevice(id = undefined) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      const result = await dispatch(casRemoveDevice(id));
      resolve(result);
    });
  };
}

export function getListInvoices(limit = undefined, offset = undefined, from = undefined, to = undefined, languageId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(listInvoices(limit, offset, from, to, languageId));
      resolve(result);
    });
  };
}

export function getTrailerUrl(mediaId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!mediaId) {
        return reject("Missing ID");
      }
      const result = await dispatch(readTrailer(mediaId));
      resolve(result);
    });
  };
}

export function getSlideTrailerUrl(trailerID) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!trailerID) {
        return reject("Missing ID");
      }
      const result = await dispatch(readSlideTrailer(trailerID));
      resolve(result);
    });
  };
}

export function resetTrailerUrl() {
  return {
    type: actionTypes.READ_TRAILER_RESET,
  };
}

export function resetSlideTrailerUrl() {
  return {
    type: actionTypes.READ_SLIDE_TRAILER_RESET,
  };
}

export function showSliderTrailer(show) {
  return {
    type: actionTypes.VISIBILITY_SHOW_SLIDER_TRAILER,
    show,
  };
}

export function getFavorites(languageId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(favoriteAssets(languageId, 0));
      const adultResult = await dispatch(favoriteAssets(languageId, 1));
      resolve([...(result?.favoriteAssets || []), ...(adultResult?.favoriteAssets || [])]);
    });
  };
}

export function addFavorite(id = undefined, isTVShow = false, isTVShowEpisode = false, languageId = "eng") {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      if (!Number.isInteger(id)) {
        return reject("Invalid ID");
      }
      const result = await dispatch(addFavoriteAsset(id, isTVShow, isTVShowEpisode));
      await dispatch(favoriteAssets(languageId, 0));
      await dispatch(favoriteAssets(languageId, 1));
      resolve(result);
    });
  };
}

export function deleteFavorite(id = undefined, isTVShow = false, isTVShowEpisode = false) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      if (!Number.isInteger(id)) {
        return reject("Invalid ID");
      }
      const result = await dispatch(removeFavoriteAsset(id, isTVShow, isTVShowEpisode));
      await dispatch(favoriteAssets(undefined, 0));
      await dispatch(favoriteAssets(undefined, 1));
      resolve(result);
    });
  };
}

export function getReferences(languageId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const hierarchyResponse = await dispatch(hierarchy(languageId));
      if (hierarchyResponse.requestFailed === true) {
        return reject(hierarchyResponse);
      }
      const menuOrderResponse = await dispatch(menuOrder(languageId));
      if (menuOrderResponse.requestFailed === true) {
        return reject(menuOrderResponse);
      }
      resolve();
    });
  };
}

export function getTvShowAsset(assetId, languageId, menuOrder) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const episodeData = await dispatch(getAsset(assetId, languageId, menuOrder));
      //check if nextEpisode exist
      if (episodeData?.items[0]?.directMetadata?.AD_NEXTEPI) {
        await dispatch(getAssetNextEpisode(episodeData?.items[0]?.directMetadata?.AD_NEXTEPI, languageId));
      }
      resolve(episodeData);
    });
  };
}

export function getPlayerInfos(asset, languageId, chosenAudio, chosenSub, subscriptionModal) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let assetId = typeof asset === "object" && asset.items.length > 0 ? asset?.items[0]?.idAsset : asset;
      if (!assetId) {
        return reject("Missing assetId");
      }
      let chosenLanguage = null;
      let audio = languageId;
      let sub = "non";
      if (chosenAudio || chosenSub) {
        audio = chosenAudio || "non";
        sub = chosenSub || "non";
      } else {
        let responseGetAsset = asset;
        if (typeof asset !== "object") {
          responseGetAsset = await dispatch(getAsset(assetId, languageId));
        }
        if (responseGetAsset && responseGetAsset.items) {
          const mediaInfo = responseGetAsset.items[0]?.directMetadata["AD_LIASMEDI"] ?? [];

          chosenLanguage = Object.values(mediaInfo).find((row) => {
            return row.audio === languageId;
          });
          if (!chosenLanguage) {
            chosenLanguage = mediaInfo[0];
          }
        }
        if (chosenLanguage) {
          audio = chosenLanguage.audio;
          sub = chosenLanguage.sub;
        }
      }
      const responseTestAsset = await dispatch(testAsset(assetId));
      if (!responseTestAsset || !responseTestAsset.newAuthToken) {
        return reject(responseTestAsset);
      }
      if (responseTestAsset && responseTestAsset.alreadyBuy === false) {
        subscriptionModal();
        resolve();
        // // const responseBuyAsset = await dispatch(buyAsset(assetId));
        // // if (!responseBuyAsset || !responseBuyAsset.newAuthToken) {
        // //   return reject(responseBuyAsset);
        // // }
      } else {
        const responseReadyAsset = await dispatch(readAsset(assetId, audio, sub));
        if (!responseReadyAsset || !responseReadyAsset.newAuthToken) {
          return reject(responseReadyAsset);
        }

        const responseGetVodLicense = await dispatch(getVodLicense(assetId, audio, sub));
        resolve(responseGetVodLicense);
        await dispatch(listAssetTags(assetId));
      }
    });
  };
}

export function getPlayerLiveInfos(channelId, startUtc, endUtc, isFta) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!channelId) {
        return reject("Missing channelId");
      }
      const responseChannelStream = await dispatch(channelStream(channelId, startUtc, endUtc));
      if (!responseChannelStream || !responseChannelStream.newAuthToken) {
        return reject(responseChannelStream);
      }

      if (isFta !== false && responseChannelStream?.url) {
        if (responseChannelStream?.url?.includes(".m3u8")) {
          await dispatch(askLicenseFP(channelId));
        } else {
          await dispatch(askLicenseWV(channelId));
        }
      }
      resolve();
    });
  };
}

export function resetPlayer() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      await dispatch(resetPlayerInfos());
      resolve();
    });
  };
}

export function changeScore(id = undefined, score, isTVShow = false, isTVShowEpisode = false) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      if (!Number.isInteger(id)) {
        return reject("Invalid ID");
      }
      const result = await dispatch(changeScoreAsset(id, score, isTVShow, isTVShowEpisode));
      resolve(result);
    });
  };
}

export function postVideoProgress(id, position) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!id) {
        return reject("Missing ID");
      }
      if (!Number.isInteger(id)) {
        return reject("Invalid ID");
      }
      if (!position && position !== 0) {
        return reject("Missing position");
      }
      if (!Number.isInteger(position)) {
        return reject("Invalid position");
      }
      const result = await dispatch(seekAsset(id, position));
      resolve(result);
    });
  };
}

export function getAvailableOffers(languageId, productId, voucherCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!languageId) {
        return reject("Missing params");
      }

      const result = await dispatch(availableOffers(languageId, productId, voucherCode));
      resolve(result);
    });
  };
}

export function getTvodOffers(languageId, productId, voucherCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!languageId) {
        return reject("Missing params");
      }

      const result = await dispatch(tvodOffers(languageId, productId));
      resolve(result);
    });
  };
}

export function getPpvOffers(languageId, productId, voucherCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!languageId) {
        return reject("Missing params");
      }

      const result = await dispatch(ppvOffers(languageId, productId, voucherCode));
      resolve(result);
    });
  };
}
export function getListOptions(languageId, offerType) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(listOptions(languageId, offerType));
      dispatch({
        type: actionTypes.UPDATE_GLOBAL_LOADER,
        payload: false,
      });
      resolve(result);
    });
  };
}
export function getActiveProducts(token) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(activeProducts(token));
      resolve(result);
    });
  };
}

export function getOptionValidity(productId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!productId) {
        return reject("Missing params");
      }

      const result = await dispatch(optionValidity(productId));
      resolve(result);
    });
  };
}

export function createNewAccount(accountData) {
  const isSafari = getFromLocal("isSafari");

  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const account = await dispatch(createAccount(accountData));
      if (!account) {
        return reject(account);
      }
      if (!account.confirmEmailSent && !account.code) {
        dispatch(setSignupStep(true));
        const loginResponse = await dispatch(
          accountLogin(
            accountData.username ? accountData.username : accountData.email,
            accountData.password,
            isSafari ? consts.safariApiKey : consts.apiKey,
            consts.graphQLGrantType,
            ["access", "offline_access"]
          )
        );
        if (!loginResponse || (!loginResponse.newAuthToken && !loginResponse.access_token)) {
          return reject(loginResponse);
        }
        const product = await dispatch(addProducts([accountData.productId]));

        if (!product) {
          return reject(product);
        }
      }

      resolve(account);
    });
  };
}

export function subscribeOptions(optionIds, productId, contentId, contentType) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(addOptions(optionIds, productId, contentId, contentType));

      resolve(result);
    });
  };
}

export function removeSubscribedOptions(optionIds, productId, idReason) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(removeOptions(optionIds, productId, idReason));

      resolve(result);
    });
  };
}

export function getListPaymentMethods(languageId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(listPaymentMethods(languageId));
      resolve(result);
    });
  };
}

export function callAskPayment(paymentData, reusePaymentData, purchasePin, voucher) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(askPayment(paymentData, reusePaymentData, purchasePin, voucher));
      resolve(result);
    });
  };
}

export function callAskChangeBankInformation(paymentData) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(askChangeBankInformation(paymentData));
      resolve(result);
    });
  };
}

export function callExecutePayment(paymentData) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(executePayment(paymentData));
      resolve(result);
    });
  };
}

export function callCheckOrderStatus(orderId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(checkOrderStatus(orderId));
      resolve(result);
    });
  };
}

export function callValidateVoucher(voucherCode) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(validateVoucher(voucherCode));
      resolve(result);
    });
  };
}

export function getListCountries(languageId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(listCountries(languageId));
      resolve(result);
    });
  };
}

export function callDocument(objectType, objectId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!objectType) {
        return reject("Missing objectType");
      }
      if (!objectId) {
        return reject("Missing objectId");
      }
      const result = await dispatch(document(objectType, objectId));
      resolve(result);
    });
  };
}

export function postAnalytics(params) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!params.level || !params.code || !params.classification || !params.applicationId) {
        return reject("Missing params");
      }
      const result = await dispatch(postAnalyticsReport(params));
      resolve(result);
    });
  };
}

export function setCurrentTrailerIdSlider(trailerId) {
  return {
    type: actionTypes.SET_CURRENT_TRAILER_ID_SLIDER,
    trailerId,
  };
}

export function resetCurrentTrailerIdSlider() {
  return {
    type: actionTypes.RESET_CURRENT_TRAILER_ID_SLIDER,
  };
}

export function resetAssetTrailerObject() {
  return {
    type: actionTypes.RESET_ASSET_TRAILER_OBJECT,
  };
}

export function setServerError() {
  return {
    type: actionTypes.SET_SERVER_ERROR,
  };
}

export function resetServerError() {
  return {
    type: actionTypes.RESET_SERVER_ERROR,
  };
}

export function setAuthToken(payload) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: payload,
  };
}

export function loginPairing(code, deviceId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!code || !deviceId) {
        return reject("Missing ID");
      }
      const result = await dispatch(loginByPairing(code, deviceId));
      resolve(result);
    });
  };
}
export function syncFromTveRequest(token, providerId, tveAdapter) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!providerId || !tveAdapter || !token) {
        return reject("Missing params");
      }

      const response = await dispatch(syncFromTve(token, providerId, tveAdapter));
      resolve(response);
    });
  };
}

export function resetGlobalState() {
  return {
    type: actionTypes.RESET_GLOBAL_STATE,
  };
}

export function createNewNPVRRecord(params) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(createNPVRRecord(params));
      resolve(result);
    });
  };
}

export function deleteRecord(params) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      const result = await dispatch(deleteNPVRRecord(params));
      resolve(result);
    });
  };
}

export function getAllNPVRRecords() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getNPVRRecords());
      resolve(result);
    });
  };
}

export function getNPVRRecord(recordId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getRecord(recordId));
      resolve(result);
    });
  };
}

export function getNpvrQuota() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      const result = await dispatch(getQuotaNPVR());
      resolve(result);
    });
  };
}

export function getNpvrPlayerInfos(recordId, languageId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      if (!recordId) {
        return reject("Missing recordId");
      }

      await dispatch(getNPVRRecord(recordId));
      const responseRecordAsset = await dispatch(readRecord(recordId, languageId));
      if (!responseRecordAsset || !responseRecordAsset.newAuthToken) {
        return reject(responseRecordAsset);
      }
      resolve(responseRecordAsset);
    });
  };
}
